import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react"; // Corrected import statement

const ProductDisplay = ({ onCheckout }) => (
  <section>
    {/* Product Display for Basic Plan */}
    <div className="product">
      <Logo />
      <div className="description">
        <h3>Basic plan</h3>
        <h5>$29.00 / month</h5>
      </div>
      <button onClick={() => onCheckout("languagepartner_basic_01")}>
        Checkout
      </button>
    </div>

    {/* Product Display for Professional Plan */}
    <div className="product">
      <Logo />
      <div className="description">
        <h3>Professional plan</h3>
        <h5>$59.00 / month</h5>
      </div>
      <button onClick={() => onCheckout("languagepartner_pro_01")}>
        Checkout
      </button>
    </div>

    {/* Product Display for Polyglott Plan */}
    <div className="product">
      <Logo />
      <div className="description">
        <h3>Polyglott plan</h3>
        <h5>$99.00 / month</h5>
      </div>
      <button onClick={() => onCheckout("languagepartner_polyglott_01")}>
        Checkout
      </button>
    </div>
  </section>
);

const SuccessDisplay = ({ sessionId }) => (
  <section>
    <div className="product Box-root">
      <Logo />
      <div className="description Box-root">
        <h3>Subscription to starter plan successful!</h3>
      </div>
    </div>
    {/* Success message and manage billing information button */}
  </section>
);

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function Subscribe() {
  const { getAccessTokenSilently } = useAuth0();
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
    const [sessionId, setSessionId] = useState('');
    const [statusMessage, setStatusMessage] = useState(''); // State to hold the status message

  useEffect(() => {
    // Check for redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }
    if (query.get('canceled')) {
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [sessionId]);

    const handleCheckout = async (lookupKey) => {
	try {
	    const accessToken = await getAccessTokenSilently();
	    const response = await fetch(process.env.REACT_APP_PAYMENT_API_URL + "/create-checkout-session", {
		method: "POST",
		headers: {
		    Authorization: `Bearer ${accessToken}`,
		    'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: `lookup_key=${lookupKey}`,
	    });
	    if (!response.ok) throw new Error('Network response was not ok.');
	    const checkoutSession = await response.json();
	    if (checkoutSession.message=="NOT_ALLOWED_VIP") {
		console.log("TRIGGERED")
		alert("As a VIP user you do not need a subscription. You are all covered!");
		return <Message message=""/>;
	    }
	    else {
		window.location.href = checkoutSession.url; // Redirect to checkout URL
	    }
	} catch (error) {
	    console.error("Checkout error:", error);
	    setMessage("An error occurred during checkout. Please try again.");
	}
    };


  if (!success && message === '') {
    return (
      <>
        <ProductDisplay onCheckout={handleCheckout} />
        {statusMessage && <p>{statusMessage}</p>} {/* Display status message */}
      </>
    );
  } else if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
}

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="14px"
    height="16px"
    viewBox="0 0 14 16"
    version="1.1"
  >
    <defs />
    <g id="Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="0-Default"
        transform="translate(-121.000000, -40.000000)"
        fill="#E184DF"
      >
        <path
          d="M127,50 L126,50 C123.238576,50 121,47.7614237 121,45 C121,42.2385763 123.238576,40 126,40 L135,40 L135,56 L133,56 L133,42 L129,42 L129,56 L127,56 L127,50 Z M127,48 L127,42 L126,42 C124.343146,42 123,43.3431458 123,45 C123,46.6568542 124.343146,48 126,48 L127,48 Z"
          id="Pilcrow"
        />
      </g>
    </g>
  </svg>
);
