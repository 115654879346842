import React, { Component } from "react";

class Landing extends Component {
      state = {
    email: ''
  };

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };


 handleSubmit = async (event) => {
    event.preventDefault();
    const response = await fetch('https://api.singularity.productions/collect-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.email,
        site: 'LanguagePartner.ai_LandingPage'  // Optional site identifier
      })
    });

    if (response.ok) {
      alert('Thank you for joining our waiting list!');
      this.setState({ email: '' }); // Reset the input field after successful submission
    } else {
      alert('There was a problem with your subscription. Please try again.');
    }
  };
    
  render() {
    return (
      <div>
        <h1>Discover the future of language learning</h1>
        <p>
          LanguagePartner.ai is an AI-powered platform committed to transforming your conversational
          abilities. Say goodbye to outdated language tandems and embrace the
          numerous benefits of learning from our expert "native speakers" in
          over 30 languages.
        </p>
	  <br/>
	  <center><iframe width="1280" height="720" src="https://www.youtube.com/embed/My73LXy0K98?si=4J9PKKUjb01nBXc5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></center>
	  <br/>
        <h2>Advantages include:</h2>
        <ul>
          <li>
            🌞 <b>Around-the-Clock Access:</b> Never worry about time zones or
            partner availability again! Our AI "native speakers" are ready
            whenever you are, even at odd hours.
          </li>
          <li>
            🤗 <b>Fearless Conversation:</b> Shed your inhibitions and build
            your speaking confidence in a supportive and non-judgmental
            environment with our AI characters.
          </li>
          <li>
            🧠 <b>Boundless Knowledge:</b> Explore captivating discussions on
            any topic that piques your interest, as our AI "native speakers"
            are well-versed in a myriad of subjects.
          </li>
          <li>
            ⏱️ <b>Focused Learning:</b> Maximize every learning moment by
            dedicating your entire practice session to mastering your target
            language and accelerating your path towards fluency.
          </li>
          <li>
            🌐 <b>Cultural Exploration:</b> Broaden your horizons by diving
            into the rich cultural nuances of different languages, all from the
            comfort of your own home.
          </li>
          <li>
            🔧 <b>Personalization:</b> Enjoy a customized learning experience
            designed to meet your goals, skill level, and preferred learning
            style, making your language journey both effective and enjoyable.
          </li>
        </ul>
        <p>
          Step into the world of <b>LanguagePartner.ai</b> and revolutionize
          your conversational skills with an innovative language learning
          experience that empowers you to conquer new linguistic heights like
          never before!
        </p>

	  <br/>
	  <center>
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="email">Join the Waiting List:</label>
          <input
            type="email"
            id="email"
            value={this.state.email}
            onChange={this.handleEmailChange}
            required
            placeholder="Enter your email"
          />
          <button type="submit">Subscribe</button>
        </form>
	      </center>

      </div>
    );
  }
}

export default Landing;
