import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

export const ProfileComponent = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [statusMessage, setStatusMessage] = useState('');

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await fetch(process.env.REACT_APP_HER_API_URL+ "/status", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (!response.ok) throw new Error('Network response was not ok.');
        const data = await response.json();
        setStatusMessage(JSON.stringify(data, null, 2)); // Store the JSON response as a string
      } catch (error) {
        console.error("Status check error:", error);
        setStatusMessage("An error occurred while checking the status. Please try again.");
      }
    };

    checkStatus(); // Call the status check function when the component mounts
  }, [getAccessTokenSilently]);

    const handleBillingPortal = async () => {
	try {
	    const accessToken = await getAccessTokenSilently();
	    const response = await fetch(process.env.REACT_APP_PAYMENT_API_URL + "/create-portal-session", { // Make sure to use the correct endpoint URL here
		method: "POST",
		headers: {
		    Authorization: `Bearer ${accessToken}`,
		    'Content-Type': 'application/json'
		},
	    });

	    if (!response.ok) throw new Error('Network response was not ok.');

	    const portalSession = await response.json();
	    window.location.href = portalSession.url; // Redirect the user to the Stripe Billing Portal
	} catch (error) {
	    console.error("Error opening billing portal:", error);
	    setStatusMessage("An error occurred while trying to open the billing portal. Please try again.");
	}
    };

  return (
      <Container className="mb-5">

      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h2>{user.name}</h2>
          <p className="lead text-muted">{user.email}</p>
        </Col>
      </Row>
	  	  <Row>
              <Col>
		  <Button color="primary" onClick={handleBillingPortal}>Manage Billing</Button>
		  <br/>
		  <br/>
              </Col>
	  </Row>

      <Row>
        <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
      </Row>
      <Row>
        {/* Display the status message */}
        <Highlight>{statusMessage}</Highlight>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
