import React from 'react';
import { PixelStreamingWrapper } from "./PixelSteamingWrapper"; // your actual path may vary

const HerConnector = ({ jobid, useLocalInstance }) => {
    const getUrlWithOptionalJobId = (baseUrl, jobid) => {
        if (useLocalInstance) {
            return `http://127.0.0.1/`;
        } else if (baseUrl.includes('127.0.0.1')) {
            return `${baseUrl}/`;
        } else {
            return `${baseUrl}/${jobid}/`;
        }
    };

    const pixelStreamingSettings = {
        AutoPlayVideo: true,
        AutoConnect: true,
        ss: getUrlWithOptionalJobId(process.env.REACT_APP_WS_PXL, jobid),
        StartVideoMuted: false,
        HoveringMouse: true,
        MatchViewportRes: false,
        KeyboardInput: false,
        MaxReconnectAttempts: 23,
        ControlsQuality: true,
        UseMic: true,
        WebRTCMinBitrate: 100,
        WebRTCMaxBitrate: 5000
    };

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <PixelStreamingWrapper initialSettings={pixelStreamingSettings} />
        </div>
    );
};

export default HerConnector;
