import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const About = () => {
  return (
    <Container className="my-5">
      <Row>
        <Col>
          <h1 className="mb-4">About LanguagePartner.AI</h1>
          <p>LanguagePartner.ai is a product of Samurai Media Limited</p>
          <address>
            1104 Crawford House<br />
            70 Queen&apos;s Road Central<br />
            Hong Kong
          </address>
          <p>Email: <a href="mailto:info@samuraimedia.org">info@samuraimedia.org</a></p>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
