import React, { Component } from "react";
import HerConnector from "./HerConnector";
import { useAuth0 } from "@auth0/auth0-react";

// This HOC wraps a component and provides it access to Auth0 functions
function withAuth0(Component) {
    return function WrappedComponent(props) {
        const { getAccessTokenSilently } = useAuth0();
        return <Component {...props} getAccessTokenSilently={getAccessTokenSilently} />;
    };
}

class LanguagePartner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPopupVisible: false,
            partners: {}, 
            showPixelStreaming: false,
            pixelStreamingUrl: null,
            jobid: '23',
            useLocalInstance: false, // Default value
            callingPartnerId: null, // New state to track the calling partner ID
        };
    }

    togglePopup = () => {
        this.setState((prevState) => ({
            isPopupVisible: !prevState.isPopupVisible,
        }));
    };


    call = async (partnerId, level = null) => {
        try {
            this.setState({ callingPartnerId: partnerId });
            
            const apiURL = 'https://api.singularity.productions/call_languagepartner';
            console.log(`Calling partner with id: ${partnerId}`);
            if(level) console.log(`Level: ${level}`);

            const accessToken = await this.props.getAccessTokenSilently();
            const response = await fetch(apiURL, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ partnerid: partnerId, localdev: this.state.useLocalInstance })
            });
            
            if (!response.ok) {
                this.setState({ callingPartnerId: null });
                throw new Error(`HTTP error! status: ${response.status}`);
            } else {
                const data = await response.json();
                if (data.error) {
                    // Notify the user if there is any error message
                    this.setState({ callingPartnerId: null });
                    alert(`Error: ${data.error}`);
                } else {
                    console.log(data);
                    let ws_pxl = "wss://api.singularity.productions/pxl/" + data.jobid + "/";
                    console.log(ws_pxl);

                    this.setState({ jobid: data.jobid, pixelStreamingUrl: ws_pxl, showPixelStreaming: true });
                }
            }
        } catch (error) {
            console.log('Fetch API error -', error);
        }
    };

    // Function to load the partners data
    loadPartners = async () => {
        try {
            const isDevMode = process.env.REACT_APP_DEV === 'true';
            const url = isDevMode ? 'languagepartner-dev.json' : 'languagepartner.json';
            const response = await fetch(url);
            const data = await response.json();

            // Group partners by language
            const partnersByLanguage = data.reduce((acc, partner) => {
                if (!acc[partner.language]) {
                    acc[partner.language] = [];
                }
                acc[partner.language].push(partner);
                return acc;
            }, {});

            this.setState({ partners: partnersByLanguage, isLoading: false });
        } catch (error) {
            console.error("Error loading partners:", error);
        }
    }
    
    componentDidMount() {    
        this.loadPartners();

        // Parse URL parameter
        const urlParams = new URLSearchParams(window.location.search);
        const useLocalInstance = urlParams.get('useLocalInstance');

        // Update state if parameter is set
        if (useLocalInstance === 'true') {
            this.setState({ useLocalInstance: true });
        }
    }
    
    render() {
        const { isLoading, partners, isPopupVisible } = this.state;
        const popupStyle = isPopupVisible ? { display: "block" } : { display: "none" };
        const { call } = this;

        if (this.state.showPixelStreaming) {
            return (
                <div>
                    {/* Render banner if useLocalInstance is true */}
                    {this.state.useLocalInstance && (
                        <div style={{ backgroundColor: 'yellow', padding: '10px', textAlign: 'center', fontWeight: 'bold' }}>
                            Local Instance is being used
                        </div>
                    )}

                    <div style={{ height: '100vh', width: '100%' }}>
                        <HerConnector 
                            jobid={this.state.jobid} 
                            useLocalInstance={this.state.useLocalInstance} 
                        />
                    </div>
                </div>      
            );
        } else {
            return (
                <div>
                    <style>
                        {`.info-icon {
                          display: inline-block;
                          margin-left: 5px;
                          cursor: pointer;
                          font-size: 18px;
                        }
                        #popup {
                          display: none;
                          position: absolute;
                          background-color: white;
                          border: 1px solid #2196F3;
                          padding: 10px;
                          width: 200px;
                          z-index: 1;
                          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                          margin-left: 5px;
                        }`}
                    </style>
                    <section style={{ padding: 20 }}>
                        {this.state.useLocalInstance && (
                            <div style={{ backgroundColor: 'yellow', padding: '10px', textAlign: 'center', fontWeight: 'bold' }}>
                                Local Instance is being used
                            </div>
                        )}

                        <h2 style={{ textAlign: "center" }}>Choose your language and partner:</h2>

                        {isLoading ? (
                            <p>Loading...</p>
                        ) : (
                            Object.keys(partners).sort().map(language => (
                                <div key={language} style={{ marginBottom: 40 }}>
                                    <h3>{language}</h3>
                                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: 20 }}>
                                        {partners[language].map(partner => (
                                            <div key={partner.id} style={{ width: 200, backgroundColor: "#f1f1f1", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", padding: 20, textAlign: "center" }}>
                                                <img src={`data:image/jpeg;base64,${partner.photo}`} alt={partner.name} style={{ width: "100%", height: "auto", borderRadius: "50%" }} />
                                                <h4>{partner.name}</h4>
                                                <p>{partner.description}</p>
                                                <button 
                                                    onClick={() => call(partner.id)} 
                                                    style={{ backgroundColor: "#2196F3", color: "white", border: "none", padding: "10px 20px", cursor: "pointer" }}>
                                                    {this.state.callingPartnerId === partner.id ? 'Calling...' : 'Call'}    
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))
                        )}
                    </section>
                </div>
            );
        }
    }
}

export default withAuth0(LanguagePartner);
