import React, { useEffect, useRef, useState } from 'react';
import {
    Config,
    AllSettings,
    PixelStreaming
} from '@epicgames-ps/lib-pixelstreamingfrontend-ue5.3';

export class NumericParameters {
    static AFKTimeoutSecs = 'AFKTimeout';
    static MinQP = 'MinQP';
    static MaxQP = 'MaxQP';
    static WebRTCFPS = 'WebRTCFPS';
    static WebRTCMinBitrate = 'WebRTCMinBitrate';
    static WebRTCMaxBitrate = 'WebRTCMaxBitrate';
    static MaxReconnectAttempts = 'MaxReconnectAttempts';
    static StreamerAutoJoinInterval = 'StreamerAutoJoinInterval';
    // Add more parameters as needed
}
export const PixelStreamingWrapper = (props) => {
    const initialSettings = props.initialSettings;

    const videoParent = useRef(null);
    const [pixelStreaming, setPixelStreaming] = useState(null);
    const [clickToPlayVisible, setClickToPlayVisible] = useState(false);

    useEffect(() => {
        if (videoParent.current) {
	    console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX");
	    console.log(initialSettings);
            const config = new Config({ initialSettings });
	    console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX");
	    console.log(config);
	    console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX");
	    
            const streaming = new PixelStreaming(config, {
                videoElementParent: videoParent.current
            });
            
            streaming.addEventListener('playStreamRejected', () => 
                setClickToPlayVisible(true)
            );

            setPixelStreaming(streaming);
	    //streaming.emitCommand({ Resolution: { Width: 888, Height: 444 } });

	    
	    const timer = setTimeout(() => {
		console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAA");
		// Assuming `config` and `NumericParameters` are accessible in this scope
		config.setNumericSetting(NumericParameters.WebRTCMaxBitrate, 2000);
		console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAA");
		console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX");
		console.log(config);
		console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX");
	    }, 10000); // 10000 milliseconds = 10 seconds

            return () => {
                try {
                    streaming.disconnect();
                } catch {}
            };
        }
    }, []);

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                position: 'relative'
            }}
        >
            <div
                style={{
                    width: '100%',
                    height: '100%'
                }}
                ref={videoParent}
            />
            {clickToPlayVisible && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        pixelStreaming?.play();
                        setClickToPlayVisible(false);
                    }}
                >
                    <div>Click to play</div>
                </div>
            )}
        </div>
    );
};
