import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import PropTypes from 'prop-types';

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Content from "./components/Content";
import Subscribe from "./components/Subscribe";
import Home from "./views/Home";
import Profile from "./views/Profile";
import ExternalApi from "./views/ExternalApi";
import Auth0Wrapper from "./Auth0Wrapper";
import { withAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import ReactGA from 'react-ga4';
import About from "./views/About";
import GodMode from "./components/GodMode";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

// Initialize Google Analytics
const TRACKING_ID = "G-DKRZLG5XCG";
ReactGA.initialize(TRACKING_ID);

class PrivateRoute extends Component {
  render() {
    const { component: Component, auth0, ...rest } = this.props;
    const { isAuthenticated, loginWithRedirect } = auth0;

    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            loginWithRedirect()
          )
        }
      />
    );
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  auth0: PropTypes.object.isRequired,
};

const PrivateRouteWithAuth0 = withAuth0(PrivateRoute);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
	error: null,
	goLive: false,
    };

    this.unlisten = null;
  }

  componentDidMount() {
    const { auth0 } = this.props;

    this.setState({ 
      isLoading: auth0.isLoading,
      error: auth0.error 
    });

    this.unlisten = history.listen((location) => {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: document.title,
      });
    });

    ReactGA.send({
      hitType: "pageview",
      page: history.location.pathname,
      title: document.title,
    });

      // Parse URL parameter
      const urlParams = new URLSearchParams(window.location.search);
      const useLocalInstance = urlParams.get('moin');

      // Update state if parameter is set
      if (useLocalInstance === '1') {
          this.setState({ goLive: true });
          
}
      
  }

  componentDidUpdate(prevProps) {
    const { auth0 } = this.props;
    if (prevProps.auth0.isLoading !== auth0.isLoading || prevProps.auth0.error !== auth0.error) {
      this.setState({
        isLoading: auth0.isLoading,
        error: auth0.error
      });
    }
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  render() {
    const { isLoading, error } = this.state;
    //const isLandingPageOnly = process.env.REACT_APP_LANDING_PAGE_ONLY === 'true';

    if (error) {
      return <div>Oops... {error.message}</div>;
    }

    if (isLoading) {
      return <Loading />;
    }

    return (
      <Auth0Wrapper>
        <Router history={history}>
          <div id="app" className="d-flex flex-column h-100">
            <NavBar isLandingPageOnly={!this.state.goLive} />
            <Container className="flex-grow-1 mt-5">
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/about" component={About} />
                {this.state.goLive && (
                  <>
                    <Route path="/connect" component={Content} />
                    <PrivateRouteWithAuth0 path="/subscribe" component={Subscribe} />
                    <PrivateRouteWithAuth0 path="/profile" component={Profile} />
		      <PrivateRouteWithAuth0 path="/godmode" component={GodMode} />
                    <PrivateRouteWithAuth0 path="/external-api" component={ExternalApi} />
                  </>
                )}
              </Switch>
            </Container>
            <Footer />
          </div>
        </Router>
      </Auth0Wrapper>
    );
  }
}

export default withAuth0(App);
