import React, { useState } from 'react';
import HerConnector from './HerConnector';
import axios from 'axios';

const GodMode = () => {
    const [sessionInitialized, setSessionInitialized] = useState(false);
    const [jobId, setJobId] = useState(null);
    const [error, setError] = useState(null);
    const [isCallActive, setIsCallActive] = useState(false);

    const handleCallButtonClick = async () => {
        try {
            const response = await axios.post('http://localhost:5023/execute_session', {
                core: { language: "english", type: "gpt-4o" },
                userid: "godmode_user",
                partnerid: "character1_eng",
                jobid: "godmode_job",
                metahuman: { id: "/Game/MetaHumans/HER01/BP_HER01.BP_HER01_C" },
                asr: { language: "en-US" },
                tts: { "type":"azure", "voice": "en-US-JennyNeural" }
            });
            
            console.log("Local session initialized:", response.data);
            setJobId(response.data.jobid || "godmode_job");
            setSessionInitialized(true);
            setIsCallActive(true);
        } catch (error) {
            console.error("Failed to initialize local session:", error);
            setError("Failed to initialize local session. Please ensure the local server is running.");
        }
    };

    const handleEndCall = () => {
        setIsCallActive(false);
        setSessionInitialized(false);
        setJobId(null);
    };

    if (error) {
        return (
            <div style={{ padding: 20, textAlign: 'center', color: 'red' }}>
                <h2>Error</h2>
                <p>{error}</p>
                <button onClick={() => setError(null)}>Dismiss</button>
            </div>
        );
    }

    return (
        <div style={{ height: '100vh', width: '100%' }}>
            <div style={{ padding: 10, backgroundColor: '#f0f0f0', textAlign: 'center' }}>
                <h2>God Mode</h2>
                {!isCallActive ? (
                    <button 
                        onClick={handleCallButtonClick}
                        style={{
                            padding: '10px 20px',
                            fontSize: '16px',
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                    >
                        Start Call
                    </button>
                ) : (
                    <>
                        <p>Connected to local instance</p>
                        <button 
                            onClick={handleEndCall}
                            style={{
                                padding: '10px 20px',
                                fontSize: '16px',
                                backgroundColor: '#f44336',
                                color: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer'
                            }}
                        >
                            End Call
                        </button>
                    </>
                )}
            </div>
            {isCallActive && sessionInitialized && (
                <HerConnector 
                    useLocalInstance={true}
                    jobid={jobId}
                />
            )}
        </div>
    );
};

export default GodMode;
